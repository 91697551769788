:root {
  --main-color: #00b140;
  --main-color-hover: #009736;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.br {
  border-radius: 0.5rem;
}

.mr1 {
  margin-right: 1rem;
}
.ml2 {
  margin-left: 2rem;
}
.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}

.mt15 {
  margin-top: 1.5rem;
}

.p1 {
  padding: 1rem;
}

.m1 {
  margin: 1rem;
}

.pt2 {
  padding-top: 2rem !important;
}
.pb0 {
  padding-bottom: 0.5rem !important;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb05 {
  margin-bottom: 0.5rem;
}
.pb2 {
  padding-bottom: 2rem !important;
}

.p2 {
  padding: 2rem;
}

.p15 {
  padding: 1.5rem;
}

.mb3 {
  margin-bottom: 3rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.mt05 {
  margin-top: 0.5rem;
}
.mr05 {
  margin-right: 0.5rem;
}

.mb250 {
  margin-bottom: 0.25rem;
}

.ml1 {
  margin-left: 1rem;
}

.m2 {
  margin: 2rem;
}

.App {
  background: #fff;
}

a {
  text-decoration: none;
  color: black;
}

nav {
  background-color: white;
  height: 56px;
  padding: 0 2rem;
  z-index: 9999;
  width: 100%;
  top: 0;
  position: fixed;
  box-shadow: 0 1px 8px rgba(2, 3, 3, 0.16);
}

label {
  font-weight: 500;
}

input,
textarea {
  border: none;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

input:focus,
textarea:focus {
  border: 1px solid var(--main-color);
}

textarea {
  font-size: 15px;
  background: white;
}

section {
  background-color: white;
  min-height: 50vh;
  padding: 4rem;
  overflow-x: hidden;
  border-bottom: 1px solid rgba(2, 3, 3, 0.1);
}

.grid {
  display: grid;
}

.fr3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.fr2 {
  grid-template-columns: 1fr 1fr;
}

.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.bigHeader {
  font-size: 38px;
}

.bigSpan {
  font-size: 22px;
}
.midSpan {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.cardShadow {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
}

.navContainer {
  max-width: 100%;
  display: flex;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.ulContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.navContainer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.infoList {
  padding: 0.75rem 1.25rem;
  text-align: left;
  width: 100%;
  font-size: 14px;
  border-top: 1px solid rgba(2, 3, 3, 0.1);
}

.privacyContainer {
  text-align: justify;
}

.privacyContainer ul {
  padding: 2rem;
  gap: 2rem;
}

.privacyContainer ul li {
  padding: 0 0.5rem;
}

.termsUl li {
  padding: 0.25rem 0 !important;
}

.selectedLink {
  color: var(--main-color) !important;
}

.privacyContainer a {
  color: var(--main-color-hover) !important;
  font-weight: bold;
}

.logoContainer h1 {
  color: black;
  font-size: 24px;
  line-height: 24px;
}

.navContainer ul li {
  margin-left: 2rem;
  font-weight: 600;
  list-style: none;
  font-size: 16px;
}

.hamburger {
  display: none;
  width: 20px;
  margin-right: 0.5rem;
}

.logo {
  width: 32px;
  margin-right: 0.175rem;
  animation: mymove 2s ease 1s 3;
}

.navPic {
  width: 32px;
  height: 32px;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 4px rgba(2, 3, 3, 0.1);
}

.mainContainer {
  margin-top: 56px;
  min-height: calc(100vh - 56px);
  overflow-x: hidden;
}

.authContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.show {
  display: flex;
}

.authContainer .signup {
  width: 450px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem;
}

.progress {
  height: 3px;
  width: 70%;
  background: var(--main-color);
}

.google,
.facebook {
  border: 1px solid rgba(2, 3, 3, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.google img,
.facebook img {
  width: 24px;
  margin-right: 1rem;
}

.facebook {
  background: #4267b2;
  color: white;
  border: 1px solid #4267b2;
}

.google span,
.facebook span {
  flex: 1;
  text-align: center;
  font-weight: 500;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerContainer {
  padding: 3rem 0 1.5rem 0;
  background-color: white;
}

.headerContainer h1 {
  font-size: 40px;
  text-align: center;
}

button {
  padding: 10px 16px;
  border: none;
  background-color: var(--main-color);
  color: white;
  font-size: 15px;
  border-radius: 4px;
  background-position: center;
  transition: all 1s;
  cursor: pointer;
}

.visibleFlex {
  display: flex !important;
  height: 100% !important;
  transition: all 0.5s ease;
  padding-bottom: 56px;
}

button:hover {
  background: var(--main-color-hover)
    radial-gradient(circle, transparent 1%, var(--main-color-hover) 1%)
    center/15000%;
}
button:active {
  background-color: var(--main-color);
  background-size: 100%;
  transition: background 0s;
}

.btnBlack {
  background-color: black;
}

.btnBlack:hover {
  background: black;
}

.btnBlack:active {
  background: black;
  transform: scale(0.98);
}

.footer {
  background: black;
  color: white;
  font-size: 13px;
}

.footer .top {
  padding: 4rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
}

.imageEditBtnCon {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer .bottom {
  padding: 1rem 2rem 1rem 2rem;
  display: grid;
  border-top: 1px solid #131313;
  grid-template-columns: 3fr 1fr;
  margin-top: 1.5rem;
}

.footer .bottom a {
  color: white !important;
  text-decoration: none !important;
  margin-right: 0.5rem;
}

.terms {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.top div {
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
}

.top .material-icons {
  font-size: 12px;
  margin-right: 0.175rem;
}

.social {
  display: flex;
  justify-content: flex-end;
}

.social a img {
  width: 30px;
  margin-right: 0.375rem;
}

.posFixed {
  position: fixed;
  left: 0;
  top: 0;
}

.pageHeader {
  padding: 2rem 2rem 0rem 2rem;
}

@media screen and (max-width: 900px) {
  section {
    padding: 2rem;
  }
  .fr2 {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (max-width: 768px) {
  nav {
    padding: 0 1rem;
  }
  .fr3 {
    grid-template-columns: 1fr 1fr !important;
  }
  .hamburger {
    display: block;
  }
  .navContainer ul {
    position: fixed;
    flex-direction: column;
    width: 100%;
    top: 56px;
    left: 0;
    bottom: 0;
    height: 0;
    background: white;
    z-index: 9999;
    overflow-y: hidden;
    transition: all 0.5s ease;
  }

  .navContainer ul li {
    margin: 1rem 0;
  }

  .serviceHeader {
    grid-template-columns: 1fr 1fr;
  }

  .bigHeader {
    font-size: 32px;
  }

  .headerContainer {
    padding: 1rem;
  }
  .headerContainer h1 {
    font-size: 32px;
  }
  .resOrder {
    order: 2;
  }
}

@media screen and (max-width: 576px) {
  section {
    padding: 2rem !important;
  }
  span,
  .bigSpan {
    font-size: 16px !important;
  }
  .fr3 {
    grid-template-columns: 1fr !important;
  }
  .bigHeader {
    font-size: 22px;
  }
  .serviceContainer {
    padding: 1rem;
  }
  .logoContainer h1 {
    font-size: 28px;
  }
  .footer .bottom {
    grid-template-columns: 1fr;
  }
  .social {
    justify-content: center;
    margin-top: 1rem;
  }
  .privacyContainer {
    padding: 1.5rem;
  }
  .privacyContainer ul {
    padding: 1rem;
    gap: 1rem;
  }
  .privacyContainer ul li {
    padding: 0;
  }
  .pageHeader {
    padding: 1rem 1rem 0rem 1rem;
  }
}
